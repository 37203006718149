import React from "react";
import "./freecontent.css";

const FreeContent = () => {
  return (
    <div className="freecontent-div">
      <div className="congrats">
        <p>Congrats! 🎉</p>
      </div>

      <div className="free-title">
        Since you filled the form, you have got access to these{" "}
        <span>FREE </span>content !
      </div>

      <div className="freebtn">
        <a
          href="https://drive.google.com/file/d/1uuZYHsxvyZybeJGvmBUGgwmbPKPJHT5p/view?usp=sharing"
          target="_blank"
          rel="noreferrer"
        >
          <button>Get Free E-Book</button>
        </a>
        <a
          href="https://drive.google.com/file/d/1cM1jJn6MiaLk788ocIvth-Or3MT_sIDP/view?usp=sharing"
          target="_blank"
          rel="noreferrer"
        >
          <button>Get Free Course</button>
        </a>
      </div>
    </div>
  );
};

export default FreeContent;