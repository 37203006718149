import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import "./counteranimation.css"
import ScrollTrigger from 'react-scroll-trigger';




const CounterAnimation = () => {
  const [counterOn, setCounterOn] = useState(false);

  function Number({ n }) {
    const { number } = useSpring({
      from : { number:0 },
      number : n,
      delay : 200,
      config : { mass : 1, tension : 20, friction :10 },
    });
    return <animated.div>{ number.to((n) => n.toFixed(0)) }</animated.div>;
    
  }

  function NumberDecimal({ n ,maxValue }) {
    const { number } = useSpring({
      from : { number:0 },
      number : n,
      delay : 200,
      config : { mass : 1, tension : 20, friction :10 },
    });
    return (
      <animated.div>
      {number.to((n) => (n >= maxValue ? maxValue.toFixed(1) : n.toFixed(1)))}
    </animated.div>
    );
    
  }
   
  return (

    <ScrollTrigger onEnter={()=> setCounterOn(true)} onExit={()=> setCounterOn(false)} >
    <div className="counter-div">
      <div className="numberCardview">
      <p>Number of students</p>
      <h1>
        {counterOn && <Number n={1548} />}
        <span>+</span>
      </h1>
    </div>

    <div  className="numberCardview">
      <p>Total course purchased</p>
      <h1>
      {counterOn && <Number n={3136} />}
      <span>+</span>
      </h1>
    </div>

    <div  className="numberCardview">
      <p>Our Rating</p>
      <h1>
      {counterOn && <NumberDecimal n={4.8} maxValue={4.8} />}
      <span>/5</span>
      </h1>
    </div>
    </div>
    </ScrollTrigger>
  );
};

export default CounterAnimation;
