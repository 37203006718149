import React from "react";
import "./freepopup.css";
import close from "../assets/closeImg.png";

const Freepopup = () => {
  const closePopup2 = () => {
    document.querySelector(".popupdiv2").style.display = "none";
  };

  return (
    <div className="popupdiv2">
      <div className="contactPage">
        <button onClick={closePopup2} className="close-btn">
          <img src={close} alt="close-button" />
        </button>
        <h1 className="contactPageTitle">
          Congrats! You have got free access to these contents <br /> <br />
          <span>FREE E-BOOK & FREE course</span>
        </h1>

        <div className="freebutn">
          <a
            href="https://drive.google.com/file/d/1uuZYHsxvyZybeJGvmBUGgwmbPKPJHT5p/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            <button>Get Free E-Book</button>
          </a>
          <a
            href="https://drive.google.com/file/d/1cM1jJn6MiaLk788ocIvth-Or3MT_sIDP/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            <button>Get Free Course</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Freepopup;